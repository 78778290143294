<template>
  <div style="width: 100vw; height: 100vh">
    <div id="map" style="width: 100vw; height: 100vh">
      <div id="mouse-position">
      </div>

    </div>
    <div id="key"></div>
  </div>
</template>

<script>
import { sourceDatalist, flyData } from './data.js'
import * as THREE from 'three'
// import { Threebox } from 'threebox-map'
import { addControl } from './component/addControl'
import { Building, BoxGeometry, Radar } from './component/model'
// import { Flamingo } from './component/flamingo'
import { CZPopup, ZSQYPopup, LineLayer } from './component/popup'
// import { Polymerization } from './component/polymerization'
import { FlyLine, Flamingo } from './component/fly'
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
export default {
  data() {
    return {
      sourceDatalist: JSON.parse(JSON.stringify(sourceDatalist))
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      // eslint-disable-next-line no-undef
      mapboxgl.accessToken =
        "pk.eyJ1IjoiZ2dvYTVzdzh0YyIsImEiOiJjbHJuOW8xbGQxOXJmMmtwN3h5anV5dXh3In0.BVFnOfNdISLxQlw9-ERV-w";

      const style = {
        version: 8,
        sources: {
          "raster-tiles": {
            type: "raster",
            tiles: [
              // wprd0{1-4}
              // scl=1&style=7 为矢量图（含路网和注记）
              // scl=2&style=7 为矢量图（含路网但不含注记）
              // scl=1&style=6 为影像底图（不含路网，不含注记）
              // scl=2&style=6 为影像底图（不含路网、不含注记）
              // scl=1&style=8 为影像路图（含路网，含注记）
              // scl=2&style=8 为影像路网（含路网，不含注记）
              "https://wprd01.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scl=1&style=7",
            ],
            tileSize: 190,
          },
        },
        layers: [
          {
            id: "simple-tiles",
            type: "raster",
            source: "raster-tiles",
            minzoom: 0,
            "maxzoom": 22
          },
        ],
      };

      // eslint-disable-next-line no-undef
      const map = new mapboxgl.Map({
        container: "map", // container ID
        style: style, // style URL
        center: [0, 0],
        zoom: 2,
        projection: 'globe', // 显示地球
        pitch: 0, //   俯仰角度
        bearing: 0, //   旋转角度
        antialias: true, // 使地图渲染时更平滑
        interactive: true, // 使地图可点击
      });

      map.flyTo({
        center: [114.410572, 30.45787], // starting position [lng, lat]
        zoom: 16, // starting zoom
        pitch: 50,
        speed: 1
      })

      map.on("style.load", () => {
        map.setFog({}) // 设置背景
        
        // 工具
        addControl(map)
        
        // eslint-disable-next-line no-undef
        const tb = (window.tb = new Threebox(
          map,
          map.getCanvas().getContext('webgl'),
          {
            defaultLights: true
          }
        ));

        // 加载建筑
        Building(map, tb)
        
        // 加载球体
        BoxGeometry(map, tb)
        
        // 雷达
        Radar(map, tb, THREE)
        
        // 火烈鸟
        Flamingo(map, tb, THREE)

        // 飞行路线
        // eslint-disable-next-line no-undef
        FlyLine(map, turf, flyData, tb)
      })

      
      // 楚臻弹出框
      // eslint-disable-next-line no-undef
      CZPopup(map, turf, this.sourceDatalist, sourceDatalist)

      // 中盛启元弹出框
      // eslint-disable-next-line no-undef
      ZSQYPopup(map, turf, this.sourceDatalist, sourceDatalist)
      
      // 线图层
      LineLayer(map, this.sourceDatalist)

      
      
      

      //注册鼠标移动事件
      map.on('mousemove', function (e) {
        document.getElementById('mouse-position').innerHTML = "经度：" + e.lngLat.lng.toFixed(5) + "，纬度：" + e.lngLat.lat.toFixed(5);
      });


      // 聚合
      // eslint-disable-next-line no-undef
      // Polymerization(map, d3)

    }
  }
}
</script>

<style lang="less">
@import './index.less';
</style>