export function addControl(map) {
  // eslint-disable-next-line no-undef
  map.addControl(new mapboxgl.FullscreenControl(), "top-left");
  // eslint-disable-next-line no-undef
  map.addControl(new mapboxgl.NavigationControl());

  map.addControl(
    // eslint-disable-next-line no-undef
    new MapboxLanguage({
      defaultLanguage: "zh-Hans",
    })
  );

  //添加绘制控件
  // eslint-disable-next-line no-undef
  var draw = new MapboxDraw({
    //不允许使用键盘交互绘制
    keybindings: false,
    //设置为ture，可按住shift+拉框来拾取图形
    boxSelect: true,
    //点击要素时的响应半径（像素单位）
    clickBuffer: 5,
    //默认控件显示方式。如果设置为true，则会添加所有的绘图控件
    displayControlsDefault: false,
    //添加指定的绘制控件
    controls: {
      //绘制线控件
      line_string: true,
      //绘制多边形控件
      polygon: true,
      //绘制点控件
      point: true,
      //删除图形控件
      trash: true,
    },
  });
  //将绘制控件添加到左上角
  map.addControl(draw, "top-left");

  /**绘制完成事件
   *  @param {string} type 事件类型（绘制完成）
   *  @param {function} fn 事件触发后的响应函数
   */
  map.on("draw.create", getGeometryInfo);
}

/**获取绘制对象的几何信息并展示
 *  @param {json对象} e 获取结果对象
 */
function getGeometryInfo(e) {
  console.log(e.features[0].geometry.coordinates);
}
