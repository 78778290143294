const geojson = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: [],
      },
    },
  ],
};

const geojsonIcon = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [],
      },
      properties: {
        bearing: 0,
      },
    },
  ],
};

function flyIconSource(map) {
  map.addSource("flyIconSource", {
    type: "geojson",
    data: geojsonIcon,
  });
}

let threeModel = null;
export function Flamingo(map, tb, THREE) {
  map.on("load", () => {
    flyIconSource(map);
    const clock = new THREE.Clock();
    map.addLayer({
      id: "custom-threebox-gbl",
      type: "custom",
      source: "flyIconSource",
      renderingMode: "3d",
      onAdd: function () {
        const scale = 1;
        const options = {
          obj: window.g.beijingUrl + "/model/Flamingo.glb",
          type: "glb",
          scale: { x: scale, y: scale, z: 1 },
          units: "meters",
          rotation: { x: 90, y: -90, z: 0 },
        };

        tb.loadObj(options, (model) => {
          threeModel = model;
          model.setCoords([114.41000222032344, 30.45878877742922]);
          model.setRotation({ x: 0, y: 0, z: 90 });
          const mesh = model.children[0];

          const s = 0.35;
          mesh.scale.set(s, s, s);
          mesh.position.y = 0;
          mesh.rotation.y = 0;

          mesh.castShadow = true;
          mesh.receiveShadow = true;

          // eslint-disable-next-line no-undef
          const mixer = new THREE.AnimationMixer(mesh);
          mixer.clipAction(model.animations[0]).setDuration(1).play();

          setInterval(() => {
            mixer.update(clock.getDelta());
          }, 100);

          tb.add(model);
        });
      },
      render: function () {
        tb.update();
      },
    });
  });
}

let counterIndex = 0;
export function FlyLine(map, turf, flyData, tb) {
  map.on("load", () => {
    map.addSource("flySourceDone", {
      type: "geojson",
      lineMetrics: true,
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: flyData,
            },
          },
        ],
      },
    });

    // 完整路径
    map.addLayer({
      id: "flyLayerDone",
      type: "line",
      source: "flySourceDone",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-width": 5,
        "line-color": "#87ceeb",
      },
    });

    map.moveLayer("flyLayerDone", "custom-threebox-gbl");

    let flag = true
    // //注册鼠标移动事件
    map.on("click", function (e) {
      const features = tb.queryRenderedFeatures({ x: e.point.x, y: e.point.y });
      if (features[0]?.object?.name === 'mesh_0') {
        counterIndex = 0
        initgGeojson(map)
        const flyDataList = flag ? flyData : JSON.parse(JSON.stringify(flyData)).reverse()
        if (!flag) {
          threeModel.setRotation({ x: 0, y: 0, z: -90 })
        } else {
          threeModel.setRotation({ x: 0, y: 0, z: 90 })
        }
        flag = !flag
        for (let i = 0; i < flyDataList.length; i++) {
          setTimeout(() => {
            animate(map, turf, flyDataList, tb);
          }, i * 100);
        }
      }
    });
  });
}


function animate(map, turf, vsourceDatalist) {
  if (counterIndex > vsourceDatalist.length - 1) {
    return;
  }

  let startPoint, endPoint;
  if (counterIndex === 0) {
    startPoint = vsourceDatalist[counterIndex];
    endPoint = vsourceDatalist[counterIndex + 1];
  } else {
    startPoint = vsourceDatalist[counterIndex - 1];
    endPoint = vsourceDatalist[counterIndex];
  }

  const bearing = turf.bearing(
    // eslint-disable-next-line no-undef
    turf.point(startPoint),
    // eslint-disable-next-line no-undef
    turf.point(endPoint)
  );
  // 计算icon角度
  // eslint-disable-next-line no-undef
  geojsonIcon.features[0].properties.bearing = bearing

  geojsonIcon.features[0].geometry.coordinates = vsourceDatalist[counterIndex];
  geojson.features[0].geometry.coordinates.push(vsourceDatalist[counterIndex]);
  map.getSource("flySourceDone").setData(geojson);

  const [longitude, latitude] = vsourceDatalist[counterIndex];
  threeModel.setCoords([longitude, latitude]);  

  counterIndex += 1;

  // requestAnimationFrame(animate)
}

function initgGeojson(map) {
  geojson.features[0].geometry.coordinates = [];
  map.getSource("flySourceDone").setData(geojson);
}
