export const sourceDatalist = [
  [114.41031030576266, 30.457707969734187],
  [114.41025556980685, 30.45779097464083],
  [114.41012987664578, 30.457910052261624],
  [114.41002427312708, 30.45801365553247],
  [114.40998856683524, 30.458115520947544],
  [114.41015348315517, 30.458188763260523],
  [114.41028406463613, 30.458274500556186],
  [114.4103992195532, 30.458362486821088],
  [114.41083711630398, 30.458659018004226],
  [114.41092156265944, 30.45873878933743],
  [114.41100222032344, 30.45878877742922],
  [114.41118458059356, 30.458943011401246],
  [114.41132138437848, 30.459002561031895],
  [114.41143133560394, 30.45909029446935],
  [114.41154391218981, 30.459180122679385],
  [114.41166609009542, 30.459236475517343],
  [114.41181159677421, 30.459381075750386],
  [114.4119442686752, 30.45947802570268],
  [114.41205222856513, 30.45953889199147],
  [114.41220907566003, 30.459616304542024],
  [114.41234081249786, 30.459695235084368],
  [114.41245967612286, 30.459783865831355],
  [114.41264237354471, 30.459891135414466],
  [114.41271903578803, 30.4599500640968],
  [114.41287566301679, 30.46007046010027],
  [114.41297789923834, 30.460149700454053],
  [114.41317705396642, 30.460248578173136],
  [114.41332256233159, 30.460359075853688],
  [114.41354479638397, 30.4605301983228],
  [114.41361493351275, 30.460564612388936],
  [114.41358860805542, 30.460672193211366],
  [114.41353056470206, 30.460754150616765],
  [114.41348753093973, 30.46082826272186],
  [114.41343002758458, 30.46086849945577],
  [114.41338047376206, 30.460945315110365],
  [114.4133400729749, 30.461034605220405],
  [114.41326015877098, 30.461115435435715],
  [114.4132070775932, 30.46120385065977],
  [114.4131612655512, 30.46128314334706],
  [114.41310470281502, 30.461364303417824],
  [114.41302893303885, 30.46147755608149],
  [114.41295508508921, 30.46158205145113],
  [114.41289879236422, 30.461676846067448],
  [114.4128157331183, 30.461787346949592],
  [114.41273021008476, 30.461908060738466],
  [114.41262595076586, 30.46204689364896],
  [114.41253434003426, 30.462161707527144],
  [114.41246668808765, 30.46231015988161],
  [114.41236940635099, 30.462433068905924],
  [114.4123096470957, 30.462535777510595],
  [114.41219599378144, 30.46265759601043],
  [114.4121186005762, 30.46280013795831],
  [114.41194597348891, 30.46299206735955],
  [114.41182819168392, 30.463239935918537],
  [114.4116655075619, 30.463502514232104],
  [114.41142493676455, 30.46339533314621],
  [114.41130576385291, 30.463340807089992],
  [114.41118214747127, 30.463347579706365],
  [114.4109983678714, 30.463293739248087],
  [114.41080669380506, 30.463287063517015],
  [114.41064133006267, 30.46322095898566],
  [114.41044562653491, 30.46319484508048],
  [114.41028175451385, 30.463168915573732],
  [114.41010892148228, 30.463143168518556],
  [114.40988690700658, 30.46311760199724],
  [114.40969744044781, 30.463073289316227],
  [114.40954968247746, 30.463035734919274],
  [114.40942616159111, 30.463023303422844],
  [114.40924393512313, 30.462961785675873],
  [114.40910488690952, 30.462931420832476],
  [114.40881751044503, 30.462889343123166],
  [114.408675960278, 30.462889343123166],
  [114.40856819570888, 30.463010914870097],
  [114.40853486046228, 30.46314958828303],
  [114.40852245099228, 30.46326047991718],
  [114.40860866424902, 30.46332056246159],
  [114.4085309629258, 30.46332056246159],
  [114.40839720231554, 30.46343675152893],
  [114.40829077764965, 30.463535185874434],
  [114.40823975175209, 30.46378522191945],
  [114.40833076991754, 30.46386501445545],
  [114.40836726770851, 30.463996599795095],
  [114.40840324265088, 30.46411077206797],
  [114.40842616385754, 30.464243830636534],
  [114.40844442328267, 30.464342417337818],
  [114.40844258003841, 30.464475255540364],
  [114.40847383809904, 30.464668494963945],
  [114.40848280977207, 30.464873436670487],
  [114.40851447349019, 30.465135103933548],
  [114.40853891294682, 30.46531631326374],
  [114.40856085685601, 30.46547901877163],
  [114.4085863351878, 30.46559865618501],
  [114.40858008409089, 30.465722131264684],
  [114.40860798399433, 30.465942163658084],
  [114.40859009045658, 30.466085072403544],
  [114.40860510397619, 30.46625835449747],
  [114.40861184409687, 30.46651007409298],
  [114.40867704390774, 30.466883230717826],
  [114.40871880017443, 30.467100547462977],
  [114.40872187154889, 30.467214565866243],
  [114.40875279959369, 30.467385516748138],
  [114.40877155267054, 30.467534234827554],
  [114.40877967454605, 30.467719719844936],
  [114.40880034773141, 30.46778404448729],
  [114.40879554173142, 30.467937560151725],
  [114.40883282556092, 30.46815401199602],
  [114.40886464712969, 30.468361344998428],
  [114.40886836406457, 30.468581704910406],
  [114.40887612633372, 30.468836602777543],
  [114.40889199055499, 30.469161338952603],
  [114.40891393587782, 30.469435812929262],
  [114.40894595769743, 30.469679714181964],
  [114.4089631214552, 30.469996367888186],
  [114.40897626623774, 30.47020988210903],
  [114.40898102775668, 30.470397921443748],
  [114.40901382008201, 30.470583401007104],
  [114.40903459519461, 30.47078555508446],
  [114.40902574602939, 30.47097326133671],
  [114.40907586566152, 30.471225007293995],
  [114.4090583328657, 30.471509048702842],
  [114.40907012335123, 30.471741067845784],
  [114.40912945393546, 30.472024977724388],
  [114.40917255397352, 30.47229155948024],
  [114.40917471177818, 30.472662260245272],
  [114.40922007924377, 30.473068607836424],
  [114.4092785509622, 30.47343319291558],
  [114.40934146043844, 30.47386267257326],
  [114.40941578968227, 30.474074069082405],
  [114.40947540775477, 30.474237220014942],
  [114.40951006343425, 30.474419541796934],
  [114.40953049307575, 30.474656778125322],
  [114.40958408949064, 30.4749789909213],
  [114.40961835946712, 30.475379639788585],
  [114.40964286365437, 30.475680091384362],
  [114.40970629175189, 30.476161704964824],
  [114.40972827894393, 30.47633536890328],
  [114.40973388654021, 30.476463745037833],
  [114.40960391231965, 30.476697624635293],
  [114.40960191867606, 30.477046361425295],
  [114.4096284035341, 30.47731993003238],
  [114.40967796534665, 30.47771007412463],
  [114.4097333571105, 30.478059711497963],
  [114.40981299556626, 30.478591501921585],
  [114.40985653839152, 30.478909601172035],
  [114.40988969452906, 30.479213031021203],
  [114.40994067827967, 30.47972390934902],
  [114.41000949438802, 30.48022864828755],
  [114.41011487345901, 30.48073414812086],
  [114.41016172419978, 30.481437288739627],
  [114.41021505116305, 30.481744401980322],
  [114.41028075841086, 30.482006835290733],
  [114.4102865446203, 30.482242447393617],
  [114.41032262074987, 30.482627313542864],
  [114.41037603448126, 30.48303683146412],
  [114.41036217723519, 30.48340086672158],
  [114.41045373525316, 30.4837343412902],
  [114.41051265432827, 30.4840389278678],
  [114.41051131342982, 30.484361114788967],
  [114.41056729640871, 30.484759442195823],
  [114.41061065783236, 30.48528551072789],
  [114.41066602412792, 30.485738483932536],
  [114.41077347390495, 30.486327746982525],
  [114.41089666026522, 30.487383492351512],
  [114.41095888684197, 30.487640851949635],
  [114.41102530492469, 30.488122042591186],
  [114.41112383653973, 30.488934812115602],
  [114.41115110262047, 30.489298701185476],
  [114.41126079880962, 30.489741584877137],
  [114.41128287319407, 30.490217996573023],
  [114.41130823993689, 30.49056337423663],
  [114.41135122163178, 30.49099000239127],
  [114.41141474570134, 30.491508587270133],
  [114.41147812595261, 30.491935308106548],
  [114.41149003990955, 30.49233893694209],
  [114.41156340447327, 30.492710008343977],
  [114.41168074959626, 30.493308991456416],
  [114.41165549348676, 30.49365029478561],
  [114.411741183954, 30.494011289127727],
  [114.4117808331556, 30.494442393013827],
  [114.41183593869198, 30.49486929919135],
  [114.4118311473365, 30.49526657560004],
  [114.41187169027296, 30.49567451359208],
  [114.41196846076866, 30.49605882215613],
  [114.41201160811988, 30.496687065456243],
  [114.41204034360135, 30.497105463714576],
  [114.41213828574809, 30.49772417189567],
  [114.4122647074683, 30.4985186579013],
  [114.41228613874938, 30.498830678689018],
  [114.41232075156171, 30.499085567406553],
  [114.4123606059348, 30.49930733241979],
  [114.41239439146494, 30.49961327130977],
  [114.4124566584045, 30.499924537116243],
  [114.41251095357376, 30.500282064478498],
  [114.41248268575151, 30.500479512013754],
  [114.41255504762222, 30.50076731489139],
  [114.41255568001975, 30.500968358379964],
  [114.41258260825322, 30.5011813840308],
  [114.41259720235735, 30.501377982743776],
  [114.41273040553205, 30.50138862358648],
  [114.41288563087596, 30.501340595604177],
  [114.4130426805254, 30.501322784438116],
  [114.41316005686616, 30.501316871251888],
  [114.41333945843269, 30.501316871251888],
  [114.4134103796087, 30.501305080468086],
  [114.41342111611067, 30.501241067575876],
  [114.41342519824656, 30.50116719440578],
  [114.41343841400987, 30.501080172140576],
  [114.41342814862998, 30.50099840070341],
  [114.41343142475023, 30.500931834863977],
  [114.41346157112349, 30.500899306611785],
  [114.41352840662381, 30.500879224973048],
  [114.41361214502285, 30.500879224973048],
];

export const flyData = [
  [114.41028702456208, 30.45760066436138],
  [114.41002442330256, 30.457765473315703],
  [114.4099013073332, 30.457927028382727],
  [114.40979029972203, 30.458156404261473],
  [114.40972419755701, 30.458358409934476],
  [114.40959379029727, 30.45872571675625],
  [114.40951439936327, 30.459054595110317],
  [114.40936980334789, 30.459391858384535],
  [114.40927646477445, 30.459966497411784],
  [114.4092944763629, 30.46033318291137],
  [114.40930712311427, 30.460763364388157],
  [114.40928961032091, 30.461342922956362],
  [114.40929007985505, 30.461958346995587],
  [114.40928172092634, 30.462487555684305],
  [114.40927252702784, 30.46286839009555],
  [114.40925723449652, 30.463332779549717],
  [114.40923318289254, 30.463676186893707],
  [114.40924269122195, 30.464107504121316],
  [114.40926977821437, 30.464585894149565],
  [114.40928324284494, 30.464999960998597],
  [114.4092796722648, 30.46543377785288],
  [114.40933128567957, 30.4659256632183],
  [114.40936570457654, 30.466418287887365],
  [114.40940188182947, 30.46693607655611],
  [114.40943881580051, 30.467464692949676],
  [114.4095218567416, 30.468347506778173],
  [114.40953185511086, 30.46865631088268],
  [114.40957989025691, 30.469020758926277],
  [114.40963072969828, 30.46940074244465],
  [114.40970649669174, 30.469885966314507],
  [114.40979933969544, 30.470269274473168],
  [114.40981422985789, 30.470606461048646],
  [114.4098364573245, 30.47085641125105],
  [114.4098882376141, 30.471253320840518],
  [114.40991758430323, 30.471578168813636],
  [114.4099791528144, 30.472064546776878],
  [114.41011262107367, 30.472710881249384],
  [114.4101555588569, 30.473151725107186],
  [114.41019156428598, 30.47362318559034],
  [114.41023565593918, 30.474294040307342],
  [114.41029205289328, 30.474885927228883],
  [114.41036074111679, 30.47572643858524],
  [114.4104501128644, 30.476419981383913],
  [114.4104959814232, 30.476947858201314],
  [114.41051782475193, 30.477272803057275],
  [114.41062114048327, 30.477704044056267],
  [114.41069551653311, 30.47813024464172],
  [114.41074458274323, 30.47861272848604],
  [114.41085397748668, 30.47908883318354],
  [114.41090135769525, 30.479474616687867],
  [114.41091963122926, 30.48000904447234],
  [114.41094451311875, 30.48038085122107],
  [114.41101500442022, 30.48097886164588],
  [114.41102561211136, 30.481424185392157],
  [114.41098497240068, 30.482326060068544],
  [114.41113549506724, 30.483194205399045],
  [114.41137516837051, 30.484031429176213],
  [114.41140126033218, 30.484334888945625],
  [114.41146700054884, 30.48475184982601],
  [114.41159780509861, 30.485317959442142],
  [114.41171420257865, 30.485940991630244],
  [114.41176090774252, 30.48652916795932],
  [114.41177609075532, 30.48720445355977],
  [114.41186831619513, 30.488037990983187],
  [114.41194408004844, 30.488935177269838],
  [114.4120691147316, 30.489702950761185],
  [114.41217437792477, 30.490319242731587],
  [114.41220650142787, 30.49082703329016],
  [114.41229031719712, 30.491374186810077],
  [114.41232832374737, 30.492025083258554],
  [114.41248585920238, 30.492711386231207],
  [114.41255082751195, 30.493328988241103],
  [114.41258059633753, 30.493863217261364],
  [114.41271673103245, 30.49486401137159],
  [114.41279076254216, 30.495559872935814],
  [114.41284226664953, 30.496044279593193],
  [114.41292099278684, 30.496516807012497],
  [114.4129198270681, 30.496957458924584],
  [114.4130079984983, 30.49756202910079],
  [114.41314255086428, 30.49806433630154],
  [114.41317729671675, 30.498623128169527],
  [114.41327922606456, 30.499324389543077],
  [114.41339914728462, 30.499883816255448],
  [114.41348221632217, 30.500399884536037],
  [114.41359402908682, 30.500820815875826],
];
