let counterIndex = 0;
let flag = true;
export function CZPopup(map, turf, vsourceDatalist, sourceDatalist) {
  map.on("load", () => {
    var el = document.createElement("div");
    el.className = "marker";
    // el.style.backgroundImage = `url(${require('./image/point2.png')})`; // 你的图标URL
    el.style.backgroundSize = "cover";
    el.style.backgroundPosition =
      "center"; /* 可选，用于控制背景图像在元素内的位置 */
    el.style.backgroundRepeat = "no-repeat"; /* 确保背景图像不平铺 */
    el.style.width = "30px";
    el.style.height = "30px";
    // eslint-disable-next-line no-undef
    new mapboxgl.Marker(el).setLngLat([114.410572, 30.45787]).addTo(map);

    const markerHeight = 100;
    const markerRadius = 10;
    const linearOffset = 25;
    const popupOffsets = {
      top: [0, 0],
      "top-left": [0, 0],
      "top-right": [0, 0],
      bottom: [0, -markerHeight],
      "bottom-left": [
        linearOffset,
        (markerHeight - markerRadius + linearOffset) * -1,
      ],
      "bottom-right": [
        -linearOffset,
        (markerHeight - markerRadius + linearOffset) * -1,
      ],
      left: [markerRadius, (markerHeight - markerRadius) * -1],
      right: [-markerRadius, (markerHeight - markerRadius) * -1],
    };
    // eslint-disable-next-line no-undef
    new mapboxgl.Popup({
      offset: popupOffsets,
      className: "my-class",
      closeOnClick: false,
    })
      .setLngLat([114.410572, 30.45787])
      .setHTML("<h1 id='popup2'>楚臻科技</h1>")
      .setMaxWidth("300px")
      .addTo(map);

    el.addEventListener("click", () => {
      // 点击 Marker 时执行的代码
      // this.$router.push("/");
    });

    document.querySelector("#popup2").addEventListener("click", () => {
      if (flag) {
        initgGeojson(map);
        counterIndex = 0;
        vsourceDatalist = JSON.parse(JSON.stringify(sourceDatalist));
        flag = false;
        for (let i = 0; i < vsourceDatalist.length; i++) {
          setTimeout(() => {
            animate(map, turf, vsourceDatalist);
            if (i === vsourceDatalist.length - 1) {
              flag = true;
            }
          }, i * 100);
        }
      }
    });
  });
}

export function ZSQYPopup(map, turf, vsourceDatalist, sourceDatalist) {
  map.on("load", () => {
    var el = document.createElement("div");
    el.className = "marker";
    el.style.backgroundImage = `url(${require("../image/point2.png")})`; // 你的图标URL
    el.style.backgroundSize = "cover";
    el.style.backgroundPosition =
      "center"; /* 可选，用于控制背景图像在元素内的位置 */
    el.style.backgroundRepeat = "no-repeat"; /* 确保背景图像不平铺 */
    el.style.width = "30px";
    el.style.height = "30px";
    // eslint-disable-next-line no-undef
    new mapboxgl.Marker(el).setLngLat([114.413644, 30.500968]).addTo(map);

    const markerHeight = 50;
    const markerRadius = 10;
    const linearOffset = 25;
    const popupOffsets = {
      top: [0, 0],
      "top-left": [0, 0],
      "top-right": [0, 0],
      bottom: [0, -markerHeight],
      "bottom-left": [
        linearOffset,
        (markerHeight - markerRadius + linearOffset) * -1,
      ],
      "bottom-right": [
        -linearOffset,
        (markerHeight - markerRadius + linearOffset) * -1,
      ],
      left: [markerRadius, (markerHeight - markerRadius) * -1],
      right: [-markerRadius, (markerHeight - markerRadius) * -1],
    };
    // eslint-disable-next-line no-undef
    new mapboxgl.Popup({
      offset: popupOffsets,
      className: "my-class",
      closeOnClick: false,
    })
      .setLngLat([114.413644, 30.500968])
      // .setHTML("<h1 id='popup1'>中盛启元</h1>")
      .setMaxWidth("300px")
      .addTo(map);

    el.addEventListener("click", () => {
      if (flag) {
        initgGeojson(map);
        counterIndex = 0;
        vsourceDatalist = JSON.parse(
          JSON.stringify(sourceDatalist)
        ).reverse();
        flag = false;
        for (let i = 0; i < vsourceDatalist.length; i++) {
          setTimeout(() => {
            animate(map, turf, vsourceDatalist);
            if (i === vsourceDatalist.length - 1) {
              flag = true;
            }
          }, i * 100);
        }
      }
    });

    // document.querySelector("#popup1").addEventListener("click", () => {
      
    // });
  });
}

const geojson = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: [],
      },
    },
  ],
};

const geojsonIcon = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [],
      },
      properties: {
        bearing: 0
      }
    },
  ],
};

function animate(map, turf, vsourceDatalist) {
  if (counterIndex > vsourceDatalist.length - 1) {
    return;
  }

  let startPoint, endPoint;
  if (counterIndex === 0) {
    startPoint = vsourceDatalist[counterIndex];
    endPoint = vsourceDatalist[counterIndex + 1];
  } else {
    startPoint = vsourceDatalist[counterIndex - 1];
    endPoint = vsourceDatalist[counterIndex];
  }

  // 计算icon角度
  // eslint-disable-next-line no-undef
  geojsonIcon.features[0].properties.bearing = turf.bearing(
    // eslint-disable-next-line no-undef
    turf.point(startPoint),
    // eslint-disable-next-line no-undef
    turf.point(endPoint)
  );

  geojsonIcon.features[0].geometry.coordinates =
    vsourceDatalist[counterIndex];
  geojson.features[0].geometry.coordinates.push(
    vsourceDatalist[counterIndex]
  );
  map.getSource("pathSource").setData(geojson);
  map.getSource("iconSource").setData(geojsonIcon);
  counterIndex += 1;

  // requestAnimationFrame(animate)
}

function initgGeojson(map) {
  geojsonIcon.features[0].geometry.coordinates = [];
  geojson.features[0].geometry.coordinates = [];
  map.getSource("pathSource").setData(geojson);
  map.getSource("iconSource").setData(geojsonIcon);
}


export function LineLayer(map, sourceDatalist) {
  const symbolIconSize = 0.2		// 图片大小

      map.on("load", () => {
        map.addSource("pathSourceDone", {
          type: "geojson",
          lineMetrics: true,
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: sourceDatalist,
                },
              },
            ],
          },
        });

        // 完整路径
        map.addLayer({
          id: "pathLayerDone",
          type: "line",
          source: "pathSourceDone",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-width": 5,
            "line-color": "#009688",
          },
        });

        map.addSource("pathSource", {
          type: "geojson",
          lineMetrics: true,
          data: geojson,
        });

        //将线添加到一个图层中，在地图中显示
        map.addLayer({
          //此id可随意设置，但是要唯一
          "id": "Line",
          //指定类型为线
          "type": "line",
          //设置数据来源
          "source": 'pathSource',
          //设置线型
          "layout": {
            //线条末端样式
            'line-cap': 'round',
            //线条相交处样式  
            'line-join': 'round'
          },
          //设置绘制参数
          "paint": {
            //设置线颜色
            "line-color": 'rgba(255, 0, 0, 0.8)',
            //设置线宽度，像素单位
            "line-width": 6
          }
        });

        map.addSource("iconSource", {
          type: "geojson",
          data: geojsonIcon,
        });

        map.loadImage(require('../image/car1.png'), (err, image) => {
          if (err) throw err;
          map.addImage("bicycle-icon", image);
          // 图标
          map.addLayer({
            id: "bicycle-layer",
            type: "symbol",
            source: "iconSource",
            layout: {
              "icon-image": "bicycle-icon",
              "icon-size": symbolIconSize,
              "icon-rotate": ["get", "bearing"],
              "icon-rotation-alignment": "map",
              "icon-allow-overlap": true,
              "icon-ignore-placement": true,
            },
          });
        });
      })
}