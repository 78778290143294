<template>
  <div id="app">
      <webgis></webgis>
  </div>
</template>

<script>
import webgis from './components/webgis/index-webgis.vue'
export default {
  components: {
    webgis
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
#app {
  width: 100vw;
  height: 100vh;
}
</style>
