
// 建筑
export function Building(map, tb) {
  map.addLayer({
    id: 'custom-threebox-model',
    type: 'custom',
    renderingMode: '3d',
    onAdd: function () {
      // Creative Commons License attribution:  Metlife Building model by https://sketchfab.com/NanoRay
      // https://sketchfab.com/3d-models/metlife-building-32d3a4a1810a4d64abb9547bb661f7f3
      const scale = 1;
      const options = {
        obj: 'https://docs.mapbox.com/mapbox-gl-js/assets/metlife-building.gltf',
        type: 'gltf',
        scale: { x: scale, y: scale, z: 1 },
        units: 'meters',
        rotation: { x: 90, y: -90, z: 0 }
      };
  
      tb.loadObj(options, (model) => {
        model.setCoords([114.410572, 30.45787]);
        model.setRotation({ x: 0, y: 0, z: 241 });
        tb.add(model);
      });
    },
  
    render: function () {
      tb.update();
    }
  });
}

// 立方体
export function BoxGeometry(map, tb) {
  map.addLayer({
    id: 'custom_layer',
    type: 'custom',
    onAdd: function () {
      // eslint-disable-next-line no-undef
      let geometry = new THREE.BoxGeometry(20, 20, 20);
      // eslint-disable-next-line no-undef
      let redMaterial = new THREE.MeshPhongMaterial({
        color: 0x009900,
        // side: THREE.DoubleSide
      });
      // eslint-disable-next-line no-undef
      let cube = new THREE.Mesh(geometry, redMaterial);
      // 单位设置为米
      cube = tb.Object3D({ obj: cube, units: 'meters', })
        .setCoords([114.41100222032344, 30.45878877742922, 50]) // 经纬度，高度为米

      tb.add(cube)
    },
    render: function () {
      tb.update();
    }
  });
}




// 雷达
export function Radar(map, tb, THREE) {
  const clock = new THREE.Clock()
  const time = {
    value: 0
  }
  const start = (delta = clock.getDelta()) => {
    time.value -= delta
    setTimeout(() => {
      // start(clock.getDelta())
      requestAnimationFrame(() => { start(clock.getDelta()) })
    }, 100)
  
  }
  
  requestAnimationFrame(start)

  map.addLayer({
    id: 'radar_layer',
    type: 'custom',
    onAdd: function () {
      const radius = 100

      // eslint-disable-next-line no-undef
      const geometry = new THREE.PlaneGeometry(radius * 2, radius * 2, 1, 1)

      // eslint-disable-next-line no-undef
      const material = new THREE.ShaderMaterial({
        uniforms: {
          // 雷达的颜色
          u_color: {
            // eslint-disable-next-line no-undef
            value: new THREE.Color('#ff8800')
          },
          // 变化的值
          u_time: time,
          // 半径
          u_radius: {
            value: radius
          },
        },
        transparent: true,
        // eslint-disable-next-line no-undef
        side: THREE.DoubleSide,
        vertexShader: `
          varying vec2 v_position;
          void main() {
            v_position = vec2(position);
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          }
        `,
        fragmentShader: `
          precision mediump float;
          varying vec2 v_position;
          uniform float u_time;
          uniform float u_radius;
          uniform vec3 u_color;

          void main() {
            float angle = atan(v_position.x, v_position.y);
            float new_angle = mod(angle + u_time, 3.1415926 * 2.0);

            // 计算距离
            float dis = distance(vec2(0.0, 0.0), v_position);

            // 外层圆环的宽度
            float borderWidth = 2.0;
            float f_opacity = 0.0;

            // 在圆环上
            if (dis > u_radius - borderWidth) {
              f_opacity = 1.0;
            }

            // 雷达扫描的显示
            if (dis < u_radius - borderWidth) {
              f_opacity = 1.0 - new_angle;
            }

            // 处于雷达之外
            if (dis > u_radius) {
              f_opacity = 0.0;
            }

            gl_FragColor = vec4(u_color, f_opacity);
          }
        `
      })

      // eslint-disable-next-line no-undef
      let cube = new THREE.Mesh(geometry, material);

      // cube.rotateX(-Math.PI / 2);
      // cube.position.set(-150, 100, 200)
      cube = tb.Object3D({ obj: cube, units: 'meters', })
        .setCoords([114.409392, 30.45677, 0]) // 经纬度，高度为米

      tb.add(cube)
    },
    render: function () {
      if (map) {
        map.triggerRepaint();
      }
      tb.update();
    }
  });
}